import React, {useState} from "react";
import { Navigate} from 'react-router-dom'
import {useEnd, useModal, useProfile} from "../context";
import ImgCheck from "../components/modals/ImgCheck";
import CodesWidget from "../components/codes/codes-widget";
import axiosInstance from "../helpers/axios";
import useEffectOnce from "../hooks/useEffectOnce";
import {pagination} from "../helpers/pagination";
import Feedback from "../components/modals/Feedback";
import Static from "../components/modals/Static";

const perPage = 6;
const prizesIds = {
    1: '500 000 ₽',
    2: 'Подарочная карта',
    3: 'Сертификат'
}

function Cabinet() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const {profile, setProfile} =  useProfile();
    const {end} = useEnd();
    const [codes, setCodes] = useState([]);
    const [codesPaging, setCodesPaging] = useState({
        total: 1,
        current_page: 1
    });

    const [prizes, setPrizes] = useState({});

    const { show, hide} = useModal();

    useEffectOnce(()=>{
        if(localStorage.getItem('auth_key')){
            getCodes();
            getPrizes();
        }
    });

    const getCodes = async(page = 1) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get('getCodes?page=' + page);
            if(response.data.result){
                setCodes(response.data.data.rows);
                setCodesPaging(response.data.data.paging);
                setLoading(false);
            }
        } catch (error) {
            if(error.response.data){
                setLoading(false);
            }
        }
    }

    const getPrizes = async() => {
        try {
            const response = await axiosInstance.get('getPrizes');
            if(response.data.result){
                let _prizes = response.data.data.reduce(function (r, a) {
                    r[a.prize_id] = r[a.prize_id] || [];
                    r[a.prize_id].push(a);
                    return r;
                }, Object.create(null));
                console.log(_prizes);
                setPrizes(_prizes);
            }
        } catch (error) {
            if(error.response.data){

            }
        }
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(codesPaging.current_page), codesPaging.total);
        if(codesPaging.total > 1){
            return(
                <div className="pager d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        if(codesPaging.current_page !== 1){
                            getCodes(parseInt(codesPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((codesPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="49" height="49" rx="24.5" transform="matrix(1 0 0 -1 0 49.5)" fill="none"/>
                            <path d="M28 16.4999L20 24.9999L28 33.4999" stroke="#E4C9B5" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="page__list d-flex align-items-center">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    if(codesPaging.current_page != page) {
                                        getCodes(parseInt(page))
                                    }
                                }} className={'pager__item ' + ((page == codesPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(codesPaging.total !== codesPaging.current_page){
                            getCodes(parseInt(codesPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((codesPaging.total === codesPaging.current_page) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="49" y="49.5" width="49" height="49" rx="24.5" transform="rotate(180 49 49.5)" fill="none"/>
                            <path d="M21 16.4999L29 24.9999L21 33.4999" stroke="#E4C9B5" strokeWidth="2"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/" replace />)
    }

    return (
        <div className="content">
            <div className="sc__lk">
                <div className="lk__bg"/>
                <div className="baloon__decor-6">
                    <img src={require("../assets/img/baloon-5.svg").default}/>
                </div>
                <div className="baloon__decor-7">
                    <img src={require("../assets/img/baloon-6.svg").default}/>
                </div>
                <div className="container">
                    <div className="sc__header d-flex justify-content-center">
                        <div className="sc__title gradient-text">
                            личный <span>кабинет</span>
                        </div>
                    </div>
                    {!profile.isLoading && Object.keys(profile.data).length > 0 && (
                        <div className="lk__wrap fadeIn animated">
                            <div className="lk__info-box d-flex align-items-center justify-content-between">
                                <div className="lk__info-left d-flex">
                                    <div className="lk__info-it text-center">
                                        <div className="lk__info-it-value">
                                            {profile.data.countChecks}
                                        </div>
                                        <div className="lk__info-it-descr">
                                            чеков <br/>
                                            загружено
                                        </div>
                                    </div>
                                    <div className="lk__info-it text-center">
                                        <div className="lk__info-it-value">
                                            {profile.data.countChecks1}
                                        </div>
                                        <div className="lk__info-it-descr">
                                            чеков <br/>
                                            принято
                                        </div>
                                    </div>
                                    <div className="lk__info-it text-center">
                                        <div className="lk__info-it-value">
                                            {profile.data.countChecks0}
                                        </div>
                                        <div className="lk__info-it-descr">
                                            чека <br/>
                                            отклонено
                                        </div>
                                    </div>
                                    <div className="lk__info-it text-center">
                                        <div className="lk__info-it-value">
                                            {('000' + profile.data.sumGarant).slice(-4)}
                                        </div>
                                        <div className="lk__info-it-descr">
                                            сумма <br/>
                                            гарантированных призов
                                        </div>
                                    </div>
                                </div>
                                <div className="lk__info-sep desk-only"/>
                                <div className="lk__info-right">
                                    <CodesWidget id="lk" classesList="btn-ct d-flex justify-content-center align-items-center" icon="true"/>
                                </div>
                            </div>
                        </div>
                    )}

                    {profile.isLoading && (
                        <div className="loader--inline fadeIn animated d-flex align-items-center justify-content-center">
                            <img src={require("../assets/img/loader.svg").default}/>
                        </div>
                    )}
                </div>
            </div>
            {!profile.isLoading && Object.keys(profile.data).length > 0 && (
                <div className="sc__lk-ct fadeIn animated">
                    <div className="baloon__decor-9 mb-only">
                        <img src={require("../assets/img/baloon-8.svg").default}/>
                    </div>
                    <div className="container">
                        <div className="sc__lk-box">
                            <div className="sc__header">
                                <div className="sc__title">
                                    Загруженные <span>чеки</span>
                                </div>
                            </div>
                            <div className="lk__wrap">
                                <div className={'tb tb--lk' + (loading ? ' tb--loading' : '')}>
                                    {codes.length > 0 && (
                                        <>
                                            <div className="tb__thead desk-only">
                                                <div className="tb__row">
                                                    <div className="tb__col">
                                                        <div className="tb__th">
                                                            № чека
                                                        </div>
                                                    </div>
                                                    <div className="tb__col">
                                                        <div className="tb__th">
                                                            Дата загрузки
                                                        </div>
                                                    </div>
                                                    <div className="tb__col">
                                                        <div className="tb__th">
                                                            Статус чека
                                                        </div>
                                                    </div>
                                                    <div className="tb__col">
                                                        <div className="tb__th">
                                                            Приз
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tb__tbody">
                                                {codes.map((code, i) => (
                                                    <div className="tb__row">
                                                        <div className="tb__col">
                                                            <div className="tb__td d-flex align-items-center">
                                                                {code.img && code.img.length > 0 && (
                                                                    <a onClick={()=>{
                                                                        show(<ImgCheck
                                                                            close={hide}
                                                                            img={code.img}
                                                                        />, "modal--form modal--img");
                                                                    }}  className="tb__img">
                                                                        <img src={code.img[0]}/>
                                                                    </a>
                                                                )}
                                                                <span>{code.id_for_user}</span>
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__td">
                                                                {code.date_create}
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__td d-flex align-items-center">
                                                                <span>{code.status.text}</span>

                                                                {(code.status.type === 'bad' || code.status.rejectReason) && (
                                                                    <div className="hint">
                                                                        <div className="hint__toggle">
                                                                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <circle cx="11" cy="11.3179" r="11" fill="#F5D3BD"/>
                                                                                <path d="M11.6031 9.74052C11.7897 9.47918 11.8831 9.18052 11.8831 8.84452C11.8831 8.50852 11.7571 8.22385 11.5051 7.99052C11.2531 7.75718 10.9404 7.64052 10.5671 7.64052C9.8764 7.64052 9.3024 7.93918 8.84507 8.53652L7.89307 7.45852C8.1544 7.03852 8.52773 6.69785 9.01307 6.43652C9.50773 6.16585 10.0584 6.03052 10.6651 6.03052C11.5704 6.03052 12.2844 6.26852 12.8071 6.74452C13.3391 7.21118 13.6051 7.79452 13.6051 8.49452C13.6051 8.92385 13.5351 9.31118 13.3951 9.65652C13.2551 10.0019 13.0824 10.2772 12.8771 10.4825C12.6717 10.6785 12.4524 10.8792 12.2191 11.0845C11.9951 11.2805 11.7851 11.4579 11.5891 11.6165C11.4024 11.7659 11.2577 11.9572 11.1551 12.1905C11.0524 12.4145 11.0244 12.6619 11.0711 12.9325L9.60107 12.9465C9.5264 12.4892 9.54973 12.0972 9.67107 11.7705C9.80173 11.4345 9.9884 11.1639 10.2311 10.9585C10.4737 10.7532 10.7164 10.5619 10.9591 10.3845C11.2111 10.2072 11.4257 9.99252 11.6031 9.74052ZM9.29307 14.9345C9.29307 14.6732 9.39573 14.4352 9.60107 14.2205C9.8064 14.0059 10.0724 13.8985 10.3991 13.8985C10.6977 13.8985 10.9451 14.0059 11.1411 14.2205C11.3371 14.4352 11.4351 14.6732 11.4351 14.9345C11.4351 15.2332 11.3371 15.4945 11.1411 15.7185C10.9451 15.9425 10.6977 16.0545 10.3991 16.0545C10.0724 16.0545 9.8064 15.9425 9.60107 15.7185C9.39573 15.4945 9.29307 15.2332 9.29307 14.9345Z" fill="#2C1E16"/>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="hint__popover">
                                                                            {code.status.rejectReason}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__td">
                                                                {code.prize.text}
                                                                {code.prize.type === 'win' && parseInt(code.prize.prize_id) > 0 && (
                                                                    <div className="d-flex">
                                                                        <div onClick={()=>{
                                                                            show(<Static prize_id={code.prize.prize_id} close={hide}/>, "modal--form modal--static");
                                                                        }} className="btn-ct btn-ct--modal btn-ct--small d-flex align-items-center justify-content-center">
                                                                            <span>Получить приз</span>
                                                                            <i className="btn__arrow">
                                                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="20" cy="20" r="20" fill="#532010"/>
                                                                                    <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                                                                </svg>
                                                                            </i>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                    {codes.length === 0 && (
                                        <div className="tb__empty d-flex align-items-center justify-content-center">
                                            Пока нет загруженных чеков
                                        </div>
                                    )}
                                </div>
                                {codesPaging.total > 1 && (
                                    renderPager()
                                )}
                            </div>
                        </div>
                        <div className="sc__lk-box">
                            <div className="sc__header">
                                <div className="sc__title">
                                    Мои <span>призы</span>
                                </div>
                            </div>
                            <div className="lk__prizes-wrap">
                                <div className="lk__prizes">
                                    <div className="row">
                                        <div className={'lk__prizes-col lk-prize--1' + (prizes['2'] ? ' active' : '')}>
                                            <div className="lk__prizes-bl">
                                                <div className="lk__prizes-bl-title text-center text-uppercase">
                                                    еженедельный приз
                                                </div>
                                                <div className="lk__prizes-img">
                                                    <div className="lk__prizes-img-wrap">
                                                        <img src={require("../assets/img/pr-1.png")}/>
                                                    </div>
                                                </div>
                                                <div className="lk__prizes-ct">
                                                    <div className="lk__prizes-title text-center text-uppercase">
                                                        30 000₽ на БАНКОВСКУЮ КАРТУ
                                                    </div>
                                                    {!prizes['2'] && (
                                                        <div className="lk__prizes-hint text-center">
                                                            Регистрируйте чеки и выигрывайте
                                                        </div>
                                                    )}

                                                </div>
                                                {!prizes['2'] && (
                                                    <CodesWidget id="week-prize" classesList="btn-ct btn-ct--modal d-flex justify-content-center align-items-center" icon="true"/>
                                                )}

                                                {prizes['2'] && !prizes['2'][0].link && (
                                                    <div className="lk__prizes-title lk__prizes-title--info text-center text-uppercase">
                                                        Ожидайте получения приза
                                                    </div>
                                                )}

                                                {prizes['2'] && prizes['2'][0].link && (
                                                    <a href={prizes['2'][0].link} className="btn-ct btn-ct--modal btn--full d-flex align-items-center justify-content-center">
                                                        <span>получить</span>
                                                        <i className="btn__arrow">
                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="20" cy="20" r="20" fill="#532010"/>
                                                                <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                                            </svg>
                                                        </i>
                                                    </a>
                                                )}
                                            </div>
                                        </div>

                                        <div className={'lk__prizes-col lk-prize--2' + (prizes['3'] ? ' active' : '')}>
                                            <div className="lk__prizes-bl">
                                                <div className="lk__prizes-bl-title text-center text-uppercase">
                                                    Специальный приз
                                                </div>
                                                <div className="lk__prizes-img">
                                                    <div className="lk__prizes-img-wrap">
                                                        <img src={require("../assets/img/pr-2.png")}/>
                                                    </div>
                                                </div>
                                                <div className="lk__prizes-ct">
                                                    <div className="lk__prizes-title text-center text-uppercase">
                                                        Подарочная карта DNS <br/>
                                                        за&nbsp;регистрацию 3&nbsp;и&nbsp;более чеков
                                                    </div>
                                                    {!prizes['3'] && (
                                                        <div className="lk__prizes-hint text-center">
                                                            Регистрируйте чеки и выигрывайте
                                                        </div>
                                                    )}

                                                </div>
                                                {!prizes['3'] && (
                                                    <CodesWidget id="special-prize" classesList="btn-ct btn-ct--modal d-flex justify-content-center align-items-center" icon="true"/>
                                                )}

                                                {prizes['3'] && !prizes['3'][0].link && (
                                                    <div className="lk__prizes-title lk__prizes-title--info text-center text-uppercase">
                                                        Ожидайте получения приза
                                                    </div>
                                                )}

                                                {prizes['3'] && prizes['3'][0].link && (
                                                    <a href={prizes['3'][0].link} className="btn-ct btn-ct--modal btn--full d-flex align-items-center justify-content-center">
                                                        <span>получить</span>
                                                        <i className="btn__arrow">
                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="20" cy="20" r="20" fill="#532010"/>
                                                                <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                                            </svg>
                                                        </i>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <div className={'lk__prizes-col lk-prize--3' + (prizes['1'] ? ' active' : '')}>
                                            <div className="lk__prizes-bl">
                                                <div className="lk__prizes-bl-title text-center text-uppercase">
                                                    главный приз
                                                </div>
                                                <div className="lk__prizes-img">
                                                    <div className="lk__prizes-img-wrap">
                                                        <img src={require("../assets/img/pr-3.png")}/>
                                                    </div>
                                                </div>
                                                <div className="lk__prizes-ct">
                                                    <div className="lk__prizes-title text-center text-uppercase">
                                                        сертификат в турагенство <br/>
                                                        на 200 000₽
                                                    </div>
                                                    {!prizes['1'] && (
                                                        <div className="lk__prizes-hint text-center">
                                                            Регистрируйте чеки и выигрывайте
                                                        </div>
                                                    )}

                                                </div>
                                                {!prizes['1'] && (
                                                    <CodesWidget id="main-prize" classesList="btn-ct btn-ct--modal d-flex justify-content-center align-items-center" icon="true"/>
                                                )}

                                                {prizes['1'] && !prizes['1'][0].link && (
                                                    <div className="lk__prizes-title lk__prizes-title--info text-center text-uppercase">
                                                        Ожидайте получения приза
                                                    </div>
                                                )}

                                                {prizes['1'] && prizes['1'][0].link && (
                                                    <a href={prizes['1'][0].link} className="btn-ct btn-ct--modal btn--full d-flex align-items-center justify-content-center">
                                                        <span>получить</span>
                                                        <i className="btn__arrow">
                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="20" cy="20" r="20" fill="#532010"/>
                                                                <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                                            </svg>
                                                        </i>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="hidden">
                <div id="update-codes" onClick={()=>{
                    getCodes(1);
                }}></div>
                <div id="inc-codes" onClick={()=>{
                    setProfile({
                        ...profile,
                        data: {
                            ...profile.data,
                            countChecks: (profile.data.countChecks + 1)
                        }
                    });
                }}></div>
            </div>
        </div>
    );
}

export default Cabinet;
