import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from "react-input-mask";
import * as yup from "yup";
import {useModal} from "../../context";
import Login from "./Login";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

function Reg({email, soc = false}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [codeEnabled, setCodeEnabled] = useState(false);
    const { show, hide} = useModal();

    const schemaObj = {
        login: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        name: yup.string().required('Обязательно к заполнению'),
        surname: yup.string().required('Обязательно к заполнению'),
        phone: yup.string()
            .test("len", 'Некорректный телефон', (val) => {
                const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                return val_length_without_dashes === 11;
            })
            .required('Некорректный телефон'),
        rules1: yup.boolean().oneOf([true], 'Необходимо согласие'),
        rules3: yup.boolean().oneOf([true], 'Необходимо согласие'),
    };

    const schema = yup.object(schemaObj).required();

    const { register, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");
    const watchAgree1 = watch("rules1");
    const watchAgree3 = watch("rules3");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("login", data.login);
        formData.append("name", data.name);
        formData.append("surname", data.surname);
        formData.append("phone", data.phone);
        formData.append("rules1", 1);
        formData.append("rules3", 1);

        try {
            const response = await axiosInstance.post('registerByEmail', formData);
            if(response.data.result){
                show(
                    <Info
                        title="ваша регистрация <br/>прошла успешно"
                        text="на указанный вами e-mail отправлены данные для входа"
                        icon={true}
                        close={hide}/>,
                    "modal--info"
                );

                if(window.ym) {
                    window.ym(97909310,'reachGoal', 'registration_success');

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "successful_user_registration"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);

                    if(window.generalPixel) {
                        window.generalPixel.postClick('a4');
                    }
                }

                if(window.dataLayer) {
                    window.dataLayer.push({
                        'event': 'customEvent',
                        'eventCategory': '7Reg'
                    });
                }
            }else{
                if(response.data.error){
                    Object.keys(response.data.error).map((err, i) => {
                        setError(err, { type: 'custom', message: response.data.error[err] });
                    });
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if(email && email != 0){
            setValue('email', email);
        }

    },[]);

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            Регистрация
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.name ? ' has-error' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            Имя
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            autoComplete="nope"
                                            {...register("name")}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p className="error__message">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.surname ? ' has-error' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            Фамилия
                                        </label>
                                        <input
                                            type="text"
                                            id="surname"
                                            autoComplete="nope"
                                            {...register("surname")}
                                        />
                                    </div>
                                    {errors.surname && (
                                        <p className="error__message">
                                            {errors.surname?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.login ? ' has-error' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            E-mail
                                        </label>
                                        <input
                                            type="text"
                                            id="login"
                                            autoComplete="nope"
                                            {...register("login")}
                                        />
                                    </div>
                                    {errors.login && (
                                        <p className="error__message">
                                            {errors.login?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            Телефон
                                        </label>
                                        <InputMask
                                            autoComplete="nope"
                                            type="text"
                                            id="phone"
                                            mask="+7 999-999-99-99"
                                            alwaysShowMask={false}
                                            {...register("phone")}
                                        />
                                    </div>
                                    {errors.phone && (
                                        <p className="error__message">
                                            {errors.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.rules1 ? ' has-error' : '')}>
                                            <label className="align-items-center">
                                                <input
                                                    type="checkbox"
                                                    {...register("rules1")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я согласен с <a href="/rules.pdf" target="_blank">правилами акции</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.rules1 && (
                                            <p className="error__message">
                                                {errors.rules1?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="checkbox__it checkbox__it--nowrap">
                                        <div className={'checkbox__wrap' + (errors.rules3 ? ' has-error' : '')}>
                                            <label className="align-items-start">
                                                <input
                                                    type="checkbox"
                                                    {...register("rules3")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я даю согласие на обработку <a href="/user-agreement.pdf" target="_blank">персональных данных</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.rules3 && (
                                            <p className="error__message">
                                                {errors.rules3?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 form__it form__it--action d-flex justify-content-center">
                                    <button disabled={isSubmitting || !watchAgree1 || !watchAgree3} type="submit" className="btn-ct btn-ct--modal btn--full d-flex align-items-center justify-content-center">
                                        <span>Зарегистрироваться</span>
                                        <i className="btn__arrow">
                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="20" r="20" fill="#532010"/>
                                                <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__subtitle text-center">
                        Есть профиль? <a onClick={()=>{
                        show(<Login/>, "modal--form modal--login");
                    }} >Авторизуйтесь</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reg;
