import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ProfileProvider, ModalProvider, EndProvider} from "./context";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Cabinet from "./pages/Cabinet";
import {Helmet} from "react-helmet";

function App() {
  return (
      <>
          <Helmet>
              <title>Подарите себе яркую осень с JARDIN</title>
              <meta property="og:title" content="Подарите себе яркую осень с JARDIN"/>
              <meta property="og:description" content="Покупайте любой кофе Jardin в сетях-партнёрах: Магнолия, Реми, Яблоко, Титан. Регистрируйте чеки на сайте и получайте гарантированные призы от 20 до 1000 ₽ на телефон за каждый чек. Примите участие в еженедельном розыгрыше 30 000 ₽ на банковскую карту."/>
          </Helmet>
          <EndProvider>
              <ProfileProvider>
                  <ModalProvider>
                      <div id="wrapper" className="wrapper">
                          <Header/>
                          <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/cabinet" element={<Cabinet />} />
                          </Routes>
                          <Footer/>
                      </div>
                  </ModalProvider>
              </ProfileProvider>
          </EndProvider>
      </>
  );
}

export default App;
