import React, {createContext, useContext, useState, useMemo} from 'react';
import useEffectOnce from "../hooks/useEffectOnce";
import axiosInstance from "../helpers/axios";

const endContextDefaultValues = {
    end: false,
    isLoading: false,
};

const EndContext = createContext({
    endContextDefaultValues,
    setEnd: () => {}
});

export const EndProvider = ({ children }) => {
    const [end, setEnd] = useState(endContextDefaultValues);
    const value = useMemo(
        () => ({ end, setEnd }),
        [end]
    );

   useEffectOnce(()=>{
       promoDates();
   });

    const promoDates = async() => {
        try {
            const response = await axiosInstance.get('endTime');
            setEnd({
                isLoading: false,
                end: response.result
            })
        } catch (error) {
            setEnd({
                isLoading: false,
                end: false
            })
        }
    }

  return (
    <EndContext.Provider value={value}>
        {useMemo(() => (
            children
        ), [])}
    </EndContext.Provider>
  )
}

export function useEnd() {
  return useContext(EndContext)
}