import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useEnd, useModal} from "../../context";
import Reg from "./Reg";
import Pass from "./Pass";
import useEffectOnce from "../../hooks/useEffectOnce";
import axiosInstance from "../../helpers/axios";

function Login(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {end} = useEnd();
    const { show, hide} = useModal();

    const schema = yup.object({
        login: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        password: yup.string().min(6, '6 символов').required('Некорректный пароль'),
        remember: yup.boolean().oneOf([false, true], 'Необходимо согласие'),
    }).required();

    const { register, setValue, watch, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("login");
    const watchPass = watch("password");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("login", data.login);
        formData.append("password", data.password);

        try {
            const response = await axiosInstance.post('authenticateByEmail', formData);
            if(response.data.result){
                localStorage.setItem('auth_key', response.data.data.auth_key);
                window.location.href = process.env.PUBLIC_URL + '/cabinet';
            }else{
                if(response.data.error){
                    Object.keys(response.data.error).map((err, i) => {
                        setError(err, { type: 'custom', message: response.data.error[err] });
                    });
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    useEffectOnce(()=>{
        setValue('remember', true);
    });

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            Вход
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.login ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            E-mail
                                        </label>
                                        <input
                                            type="text"
                                            id="login"
                                            autoComplete="nope"
                                            {...register("login")}
                                        />
                                    </div>
                                    {errors.login && (
                                        <p className="error__message">
                                            {errors.login?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.password ? ' has-error' : '') + ((watchPass && watchPass != '') ? ' has-value' : '')}>
                                        <label htmlFor="password" className="form__label">
                                            Пароль
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            {...register("password")}
                                        />
                                    </div>
                                    {errors.password && (
                                        <p className="error__message">
                                            {errors.password?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action d-flex align-items-center justify-content-between">
                                    <div className="checkbox__wrap">
                                        <label className="align-items-center">
                                            <input
                                                type="checkbox"
                                                {...register("remember")}
                                            />
                                            <div className="checkbox__decor"/>
                                            <div className="checkbox__title">
                                                Запомнить меня
                                            </div>
                                        </label>
                                    </div>
                                    <div className="modal__form-text">
                                        <a onClick={()=>{
                                            show(<Pass/>, "modal--form modal--pass");
                                        }}>Забыли пароль?</a>
                                    </div>
                                </div>
                                <div className="col-12 form__it form__it--action d-flex justify-content-center">
                                    <button type="submit" className="btn-ct btn-ct--modal btn--full d-flex align-items-center justify-content-center">
                                        <span>Войти</span>
                                        <i className="btn__arrow">
                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="20" r="20" fill="#532010"/>
                                                <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {!end.end && (
                    <div className="modal__ct-box">
                        <div className="modal__subtitle text-center">
                            Нет профиля?&nbsp;&nbsp;<a onClick={()=>{
                            show(<Reg/>, "modal--form modal--reg");
                            if(window.generalPixel) {
                                window.generalPixel.postClick('a3');
                            }
                        }} >Зарегистрируйтесь</a>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Login;
