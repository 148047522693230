import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import {useModal, useProfile} from "../context";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {goToAnchor} from "../helpers/go-to-anchor";
import {findGetParameter} from "../helpers/find-get-param";
import { ScrollPercentage } from 'react-scroll-percentage'
import {pagination} from "../helpers/pagination";
import {CustomScroll} from 'react-custom-scroll';
import Feedback from "../components/modals/Feedback";
import CodesWidget from "../components/codes/codes-widget";

const faq = [
    {
        title: 'Какие сроки проведения акции?',
        answer: 'Период покупки товара и&nbsp;регистрации чека на&nbsp;сайте акции с&nbsp;1&nbsp;августа по&nbsp;30&nbsp;сентября 2024&nbsp;г.<br/>' +
            'Общий срок проведения акции (период покупки, определения победителей и&nbsp;вручения призов): <b>с&nbsp;1&nbsp;августа по&nbsp;15&nbsp;октября 2024&nbsp;г.</b>'
    },
    {
        title: 'Откуда принимаются чеки?',
        answer: 'К&nbsp;регистрации принимаются только чеки из&nbsp;магазинов:<br/>' +
            '<b>&laquo;Магнолия&raquo;, &laquo;Реми&raquo;, &laquo;Яблоко&raquo;, &laquo;Титан&raquo;&nbsp;&mdash;</b> с&nbsp;01&nbsp;августа по&nbsp;30&nbsp;сентября 2024&nbsp;г.<br/>' +
            'Подробнее с&nbsp;требованиями к&nbsp;формату чека и&nbsp;фото можно ознакомиться в&nbsp;правилах акции.'
    },
    {
        title: 'Кто может принять участие в&nbsp;акции?',
        answer: 'Граждане Российской Федерации, достигшие 18&nbsp;лет.'
    },
    {
        title: 'Что необходимо сделать для участия в&nbsp;акции?',
        answer: '1. Купить минимум 1&nbsp;любую упаковку кофе Jardin, имеющуюся в&nbsp;продаже в&nbsp;розничном Магазине <b>&laquo;Магнолия&raquo;, &laquo;Реми&raquo;, &laquo;Яблоко&raquo;, &laquo;Титан&raquo;</b>.<br/>' +
            '2. Сохранить чек на&nbsp;покупку товара до&nbsp;конца общего срока проведения акции.<br/>' +
            '3. Зарегистрироваться и&nbsp;загрузить чек в&nbsp;период регистрации чеков на&nbsp;сайте jardin.promo, указав свои фамилию, имя, номер мобильного телефона и&nbsp;адрес электронной почты.<br/>' +
            '4. Для участия в&nbsp;розыгрыше специального приза (&laquo;Подарочная карта DNS&raquo; на&nbsp;30&nbsp;000&nbsp;рублей) необходимо зарегистрировать не&nbsp;менее 3&nbsp;чеков из&nbsp;одной торговой сети.'
    },
    {
        title: 'Какая продукция Jardin участвует в&nbsp;акции?',
        answer: 'В&nbsp;акции участвуют все виды товаров бренда Jardin.'
    },
    {
        title: 'Какие призы можно выиграть?',
        answer: '<b>Призы, разыгрываемые в&nbsp;торговой сети &laquo;Магнолия&raquo;</b>:<br/>' +
            '<b>Гарантированные призы</b>&nbsp;&mdash; деньги на&nbsp;баланс номера мобильного телефона:<br/>' +
            '20&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '30&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '50&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '70&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '90&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '100 рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '300 рублей на&nbsp;телефон&nbsp;&mdash; 70&nbsp;штук.<br/>' +
            '500 рублей на&nbsp;телефон&nbsp;&mdash; 20&nbsp;штук.<br/>' +
            '1000 рублей на&nbsp;телефон&nbsp;&mdash; 10&nbsp;штук.<br/>' +
            'Номинал приза выпадает случайным образом.<br/>' +
            '<b>Еженедельный приз:</b><br/>' +
            '30&nbsp;000 рублей на&nbsp;банковскую карту победителя&nbsp;&mdash; 8&nbsp;штук.<br/>' +
            '<b>Специальный приз:</b><br/>' +
            '&laquo;Подарочая карта DNS&raquo; на&nbsp;30&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.<br/>' +
            'Разыгрывается среди участников, зарегистрировавших 3&nbsp;и&nbsp;более чека.<br/>' +
            '<b>Главный приз:</b><br/>' +
            'Подарочный сертификат &laquo;Level.Travel&raquo; номиналом 200&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.<br/>' +
            '<br/>' +
            '<b>Призы, разыгрываемые в&nbsp;торговой сети &laquo;Реми&raquo;:</b><br/>' +
            '<b>Гарантированные призы</b>&nbsp;&mdash; деньги на&nbsp;баланс номера мобильного телефона:<br/>' +
            '20&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '30&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '50&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '70&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '90&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '100 рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '300 рублей на&nbsp;телефон&nbsp;&mdash; 70&nbsp;штук.<br/>' +
            '500 рублей на&nbsp;телефон&nbsp;&mdash; 20&nbsp;штук.<br/>' +
            '1000 рублей на&nbsp;телефон&nbsp;&mdash; 10&nbsp;штук.<br/>' +
            'Номинал приза выпадает случайным образом.<br/>' +
            '<b>Еженедельный приз:</b><br/>' +
            '30&nbsp;000 рублей на&nbsp;банковскую карту победителя&nbsp;&mdash; 8&nbsp;штук.<br/>' +
            '<b>Специальный приз:</b><br/>' +
            '&laquo;Подарочая карта DNS&raquo; на&nbsp;30&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.<br/>' +
            'Разыгрывается среди участников, зарегистрировавших 3&nbsp;и&nbsp;более чека.<br/>' +
            '<b>Главный приз:</b><br/>' +
            'Подарочный сертификат &laquo;Level.Travel&raquo; номиналом 200&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.<br/>' +
            '<br/>' +
            '<b>Призы, разыгрываемые в&nbsp;торговой сети &laquo;Яблоко&raquo;:</b><br/>' +
            '<b>Гарантированные призы</b>&nbsp;&mdash; деньги на&nbsp;баланс номера мобильного телефона:<br/>' +
            '20&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '30&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '50&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '70&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '90&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '100 рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '300 рублей на&nbsp;телефон&nbsp;&mdash; 70&nbsp;штук.<br/>' +
            '500 рублей на&nbsp;телефон&nbsp;&mdash; 20&nbsp;штук.<br/>' +
            '1000 рублей на&nbsp;телефон&nbsp;&mdash; 10&nbsp;штук.<br/>' +
            'Номинал приза выпадает случайным образом.<br/>' +
            '<b>Еженедельный приз:</b><br/>' +
            '30&nbsp;000 рублей на&nbsp;банковскую карту победителя&nbsp;&mdash; 8&nbsp;штук.<br/>' +
            '<b>Специальный приз:</b><br/>' +
            '&laquo;Подарочая карта DNS&raquo; на&nbsp;30&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.<br/>' +
            'Разыгрывается среди участников, зарегистрировавших 3&nbsp;и&nbsp;более чека.<br/>' +
            '<b>Главный приз:</b><br/>' +
            'Подарочный сертификат &laquo;Level.Travel&raquo; номиналом 200&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.<br/>' +
            '<br/>' +
            '<b>Призы, разыгрываемые в&nbsp;торговой сети &laquo;Титан&raquo;:</b><br/>' +
            '<b>Гарантированные призы</b>&nbsp;&mdash; деньги на&nbsp;баланс номера мобильного телефона:<br/>' +
            '20&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '30&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '50&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '70&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '90&nbsp;рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '100 рублей на&nbsp;телефон&nbsp;&mdash; 50&nbsp;штук.<br/>' +
            '300 рублей на&nbsp;телефон&nbsp;&mdash; 70&nbsp;штук.<br/>' +
            '500 рублей на&nbsp;телефон&nbsp;&mdash; 20&nbsp;штук.<br/>' +
            '1000 рублей на&nbsp;телефон&nbsp;&mdash; 10&nbsp;штук.<br/>' +
            'Номинал приза выпадает случайным образом.<br/>' +
            '<b>Еженедельный приз:</b><br/>' +
            '30&nbsp;000 рублей на&nbsp;банковскую карту победителя&nbsp;&mdash; 8&nbsp;штук.<br/>' +
            '<b>Специальный приз:</b><br/>' +
            '&laquo;Подарочая карта DNS&raquo; на&nbsp;30&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.<br/>' +
            'Разыгрывается среди участников, зарегистрировавших 3&nbsp;и&nbsp;более чека.<br/>' +
            '<b>Главный приз:</b><br/>' +
            'Подарочный сертификат &laquo;Level.Travel&raquo; номиналом 200&nbsp;000 рублей&nbsp;&mdash; 1&nbsp;штука.'
    },
    {
        title: 'Как зарегистрировать чек?',
        answer: 'Для того, чтобы зарегистрировать чек, необходимо:<br/>' +
            '&bull; Зарегистрироваться или авторизоваться на&nbsp;сайте акции jardin.promo.<br/>' +
            '&bull; Нажать на&nbsp;кнопку &laquo;зарегистрировать чек&raquo;.<br/>' +
            '&bull; Загрузить чек.'
    },
    {
        title: 'Каким способом можно загрузить чек?',
        answer: '1) Отсканировать QR-код, расположенный на&nbsp;чеке, при помощи вашего телефона.<br/>' +
            '2) Ввести данные с&nbsp;чека вручную, указав: дату и&nbsp;время чека, сумму чека, ФН, ФД, ФП&nbsp;или ФПД.<br/>' +
            '3) Загрузить фотографию чека. (Фотография чека должна быть сделана в&nbsp;надлежащем качестве с&nbsp;разборчивой информацией).'
    },
    {
        title: 'Если не&nbsp;получается зарегистрировать чек?',
        answer: 'Обратитесь в&nbsp;службу поддержки через форму обратной связи на&nbsp;сайте. Срок предоставления обратной связи может составлять до&nbsp;3&nbsp;рабочих дней.'
    },
    {
        title: 'Как долго проверяется чек?',
        answer: 'Срок проверки чека может составлять до&nbsp;3&nbsp;рабочих дней.'
    },
    {
        title: 'Сколько чеков я&nbsp;могу зарегистрировать?',
        answer: 'Каждый участник может зарегистрировать в&nbsp;акции неограниченное количество чеков.'
    },
    {
        title: 'Нужно&nbsp;ли мне сохранять чек?',
        answer: 'Участник обязан хранить оригиналы зарегистрированных чеков в&nbsp;течение общего срока проведения акции.'
    },
    {
        title: 'Какое количество призов можно получить за&nbsp;весь период акции?',
        answer: 'Один участник может получить гарантированные призы на&nbsp;сумму, не&nbsp;превышающую 3&nbsp;000&nbsp;рублей, и&nbsp;не&nbsp;более 1&nbsp;приза из&nbsp;любой другой категории призов за&nbsp;весь срок проведения акции.'
    },
    {
        title: 'Можно&nbsp;ли получить приз деньгами?',
        answer: 'Выдача призов в&nbsp;их&nbsp;денежном эквиваленте не&nbsp;производится, также не&nbsp;предусмотрен возврат и&nbsp;обмен призов.'
    },
    {
        title: 'Как определяются победители в&nbsp;акции?',
        answer: 'Претендент на&nbsp;получение Еженедельного приза определяется по&nbsp;следующей формуле:<br/>' +
            'N= KК * Е+1,<br/>' +
            'где:<br/>' +
            'N&nbsp;&mdash; номер чека претендента на&nbsp;получение еженедельного приза<br/>' +
            'КК&nbsp;&mdash; общее количество зарегистрированных чеков в&nbsp;реестре за&nbsp;соответствующий период.<br/>' +
            'Е&nbsp;&mdash; дробная часть (четыре цифры после запятой) числа, являющегося курсом евро к&nbsp;рублю&nbsp;РФ, установленного Центральным банком&nbsp;РФ на&nbsp;день определения победителя, при этом целая часть числа обнуляется (например, если евро по&nbsp;данным ЦБ&nbsp;РФ&nbsp;к&nbsp;рублю&nbsp;РФ составил 94,8151&nbsp;то Е= 0,8151). Информация о&nbsp;курсе евро доступна на&nbsp;сайте www.cbr.ru.<br/>' +
            'В&nbsp;случае если N&nbsp;&mdash; нецелое число, оно округляется в&nbsp;меньшую сторону (его дробная часть после запятой отбрасывается).<br/>' +
            '<br/>' +
            'Претендент на&nbsp;получение Cпециального приза определяется по&nbsp;следующей формуле:<br/>' +
            'N = KК * Е+1<br/>' +
            'где:<br/>' +
            'N&nbsp;&mdash; номер чека претендента на&nbsp;получение специального приза<br/>' +
            'КК&nbsp;&mdash; общее количество зарегистрированных чеков за&nbsp;соответствующий период.<br/>' +
            'Е&nbsp;&mdash; дробная часть (четыре цифры после запятой) числа, являющегося курсом доллара к&nbsp;рублю&nbsp;РФ, установленного Центральным банком&nbsp;РФ на&nbsp;день определения победителя, при этом целая часть числа обнуляется (например, если доллар по&nbsp;данным ЦБ&nbsp;РФ&nbsp;к&nbsp;рублю&nbsp;РФ составил 86,9561&nbsp;то Е= 0,7695). Информация о&nbsp;курсе доллара доступна на&nbsp;сайте www.cbr.ru.<br/>' +
            'В&nbsp;случае если N&nbsp;&mdash; нецелое число, оно округляется в&nbsp;меньшую сторону (его дробная часть после запятой отбрасывается).<br/>' +
            '<br/>' +
            'Претендент на&nbsp;получение Главного приза определяется по&nbsp;следующей формуле:<br/>' +
            'N = KК * Е+1<br/>' +
            'где:<br/>' +
            'N&nbsp;&mdash; номер чека претендента на&nbsp;получение главного приза<br/>' +
            'КК&nbsp;&mdash; общее количество зарегистрированных чеков за&nbsp;соответствующий период.<br/>' +
            'Е&nbsp;&mdash; дробная часть (четыре цифры после запятой) числа, являющегося курсом доллара к&nbsp;рублю&nbsp;РФ, установленного Центральным банком&nbsp;РФ на&nbsp;день определения победителя, при этом целая часть числа обнуляется (например, если доллар по&nbsp;данным ЦБ&nbsp;РФ&nbsp;к&nbsp;рублю&nbsp;РФ составил 86,9561&nbsp;то Е= 0,7695). Информация о&nbsp;курсе доллара доступна на&nbsp;сайте www.cbr.ru.<br/>' +
            'В&nbsp;случае если N&nbsp;&mdash; нецелое число, оно округляется в&nbsp;меньшую сторону (его дробная часть после запятой отбрасывается)'
    },
    {
        title: 'Как можно узнать, что стал победителем?',
        answer: 'Каждому победителю отправляется информационное письмо на&nbsp;адрес электронной почты участника, указанный при регистрации на&nbsp;сайте акции.'
    },
    {
        title: 'Как вручаются призы?',
        answer: '<b>Гарантированные призы</b>&nbsp;&mdash; деньги на&nbsp;баланс номера мобильного телефона.<br/>' +
            'Отправляются на&nbsp;баланс номера мобильного телефона, указанный участником при регистрации на&nbsp;сайте, при условии принадлежности номера телефона российским операторам связи.<br/>' +
            '<b>Еженедельный приз</b>&nbsp;&mdash; 30&nbsp;000 рублей на&nbsp;банковскую карту победителя.<br/>' +
            'После положительной проверки полученной от&nbsp;победителя информации перечисляется на&nbsp;банковский счет победителя.<br/>' +
            '<b>Специальный приз</b>&nbsp;&mdash; подарочная карта DNS на&nbsp;30&nbsp;000&nbsp;рублей.<br/>' +
            'После положительной проверки полученной от&nbsp;победителя информации направляется курьерской службой за&nbsp;счет организатора по&nbsp;указанному победителем адресу доставки.<br/>' +
            '<b>Главный приз</b>&nbsp;&mdash; подарочный сертификат Level.Travel номиналом 200&nbsp;000&nbsp;рублей.<br/>' +
            'После положительной проверки полученной от&nbsp;победителя информации доставляется победителю в&nbsp;качестве электронного сертификата на&nbsp;адрес электронной почты участника, указанной при регистрации на&nbsp;сайте.'
    },
    {
        title: 'Кто является организатором акции?',
        answer: 'ООО &laquo;Эктивейт&raquo;, место нахождения: 115054, Москва&nbsp;г., ул. Дубининская, д.&nbsp;57, стр.&nbsp;1, этаж&nbsp;1, офис 111, ОГРН 5087746026286, ИНН: 7706696551, КПП: 772401001.'
    },
    {
        title: 'Что делать, если возникли вопросы, ответов на&nbsp;которые нет в&nbsp;этом FAQ?',
        answer: 'Задайте нам свой вопрос через форму обратной связи на&nbsp;сайте. Мы&nbsp;обязательно Вам ответим! Срок предоставления обратной связи может составлять до&nbsp;3&nbsp;дней.<br/>' +
            'Подробнее с&nbsp;правилами акции&nbsp;Вы можете ознакомиться, скачав полную версию правил по&nbsp;<a href="/rules.pdf" target="_blank">ссылке</a>.'
    },
];

let depths = {
    25: false,
    50: false,
    75: false,
    100: false
};

function Home() {
    const [faqCur, setFaqCur] = useState(0);
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [winType, setWinType] = useState(1);
    const [winnersEmpty, setWinnersEmpty] = useState('Победители не определены');
    const [winners, setWinners] = useState([]);
    const [winPaging, setWinPaging] = useState({
        total: 1,
        current_page: 1
    });

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    const onSearchSubmit = async (data) => {
        setLoading(true);
        setSearchLoading(true);
        getWinners(1, true);
    }

    const { show, hide} = useModal();

    useEffect(() => {
        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let result = findGetParameter('return') ?? findGetParameter('result');
            let auth_key = findGetParameter('auth_key');

            const url = new URL(window.location.href);
            if(parseInt(result) === 1 && auth_key !== null){
                localStorage.setItem('auth_key', auth_key);
                window.location.href = process.env.PUBLIC_URL + '/cabinet';
            }
        }

        if(window.location.hash){
            goToAnchor(window.location.hash.substring(1));
            window.history.pushState({param: 'Value'}, '', '/');
        }
    },[]);

    useEffect(()=>{
        getWinners(1);
    }, []);

    const getWinners = async(page = 1, search = false) => {
        setLoading(true);
        let query = process.env.REACT_APP_API_PROXY + 'getWinners?page=' + page + (search ? '&email=' + watchEmail : '');
        await fetch(query, {
            method: "GET",
            headers: {
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setWinners(response.data.rows);
                setWinPaging(response.data.paging);
                setLoading(false);

                if(search){
                    setWinPaging({
                        total: 1,
                        current_page: 1
                    });
                    setWinnersEmpty('Среди победителей данный аккаунт не найден');
                }else{
                    setWinnersEmpty('Победители не определены');
                }

                setSearchLoading(false);

            }
        });
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(winPaging.current_page), winPaging.total);
        if(winPaging.total > 1){
            return(
                <div className="pager d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        if(winPaging.current_page !== 1){
                            getWinners(parseInt(winPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((winPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="49" height="49" rx="24.5" transform="matrix(1 0 0 -1 0 49.5)" fill="#603F2F"/>
                            <path d="M28 16.4999L20 24.9999L28 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="page__list d-flex align-items-center">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    if(winPaging.current_page != page) {
                                        getWinners(parseInt(page))
                                    }
                                }} className={'pager__item ' + ((page == winPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(winPaging.total !== winPaging.current_page){
                            getWinners(parseInt(winPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((winPaging.total === winPaging.current_page) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="49" y="49.5" width="49" height="49" rx="24.5" transform="rotate(180 49 49.5)" fill="#603F2F"/>
                            <path d="M21 16.4999L29 24.9999L21 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    return (
        <ScrollPercentage triggerOnce={true} onChange={(percentage) => {
            if(!depths['25'] && percentage >= 0.25) {
                if(window.ym) {
                    window.ym(97909310,'reachGoal', 'scroll25');

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "scroll_25"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);
                }
                depths['25'] = true;
            }

            if(!depths['50'] && percentage >= 0.5) {
                if(window.ym) {
                    window.ym(97909310,'reachGoal', 'scroll50');

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "scroll_50"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);
                }
                depths['50'] = true;
            }

            if(!depths['75'] && percentage >= 0.75) {
                if(window.ym) {
                    window.ym(97909310,'reachGoal', 'scroll75');

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "scroll_75"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);
                }
                depths['75'] = true;
            }

            if(!depths['100'] && percentage >= 0.83) {
                if(window.ym) {
                    window.ym(97909310,'reachGoal', 'bottom');

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "scroll_in_footer"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);
                }
                depths['100'] = true;
            }
        }}>
            <div className="content">
                <div id="promo" className="sc__promo">
                    <div className="promo__bg"/>
                    <div className="promo__products">
                        <img src={require("../assets/img/promo-products.png")}/>
                    </div>
                    <div className="baloon__decor-5">
                        <img src={require("../assets/img/baloon-1.svg").default} className="desk-only"/>
                        <img src={require("../assets/img/baloon-7.svg").default} className="mb-only"/>
                    </div>
                    <div className="baloon__decor-11 mb-only">
                        <img src={require("../assets/img/baloon-4.svg").default}/>
                    </div>
                    <div className="container">
                        <div className="promo__date text-center">
                            с&nbsp;1&nbsp;августа по&nbsp;30&nbsp;сентября 2024 года
                        </div>
                        <div className="promo__title">
                            Подарите себе <br/>
                            яркую осень с JARDIN
                        </div>
                        <div className="promo__action d-flex justify-content-center">
                            <CodesWidget id="promo" classesList="btn-ct d-flex justify-content-center align-items-center" icon="true"/>
                        </div>
                        <div className="steps d-flex justify-content-center align-items-start">
                            <div className="steps__it">
                                <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                    1
                                </div>
                                <div className="steps__it-title text-center text-uppercase">
                                    Купите
                                </div>
                                <div className="steps__it-descr text-center text-uppercase">
                                    любой кофе Jardin <br/>
                                    в сетях-партнерах
                                </div>
                            </div>
                            <div className="steps__it">
                                <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                    2
                                </div>
                                <div className="steps__it-title text-center text-uppercase">
                                    Зарегистрируйте
                                </div>
                                <div className="steps__it-descr text-center text-uppercase">
                                    чек на сайте
                                </div>
                            </div>
                            <div className="steps__it">
                                <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                    3
                                </div>
                                <div className="steps__it-title text-center text-uppercase">
                                    Получите
                                </div>
                                <div className="steps__it-descr text-center text-uppercase">
                                    Гарантированный приз <br/>
                                    от 20₽ до 1000₽ на телефон
                                </div>
                            </div>
                            <div className="steps__it">
                                <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                    4
                                </div>
                                <div className="steps__it-title text-center text-uppercase">
                                    Участвуйте
                                </div>
                                <div className="steps__it-descr text-center text-uppercase">
                                    в розыгрыше призов
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="partners" className="sc__partners">
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title text-center">
                                Сети-партнеры
                            </div>
                        </div>
                        <div className="partners__wrap">
                            <div className="partners__list d-flex align-items-start justify-content-center">
                                <div className="partners__it partners__it--1">
                                    <img src={require("../assets/img/p-1.png")} className="desk-only"/>
                                    <img src={require("../assets/img/p-1-sm.png")} className="mb-only"/>
                                </div>
                                <div className="partners__it partners__it--2">
                                    <img src={require("../assets/img/p-2.png")} className="desk-only"/>
                                    <img src={require("../assets/img/p-2-sm.png")} className="mb-only"/>
                                </div>
                                <div className="partners__it partners__it--3">
                                    <img src={require("../assets/img/p-3.svg").default}/>
                                </div>
                                <div className="partners__it partners__it--4">
                                    <img src={require("../assets/img/p-4.png")} className="desk-only"/>
                                    <img src={require("../assets/img/p-4-sm.png")} className="mb-only"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="prizes" className="sc__prizes">
                    <div className="container">
                        <div className="prizes__wrap">
                            <div className="prizes__list d-flex flex-wrap justify-content-center">
                                <div className="prizes__it">
                                    <div className="sc__title text-center text-uppercase">
                                        Еженедельный <br/>
                                        розыгрыш
                                    </div>
                                    <div className="prizes__it-img">
                                        <img src={require("../assets/img/pr-1.png")}/>
                                    </div>
                                    <div className="prizes__it-descr">
                                        30&nbsp;000₽ на&nbsp;банковскую карту <br/>
                                        победителя
                                    </div>
                                </div>
                                <div className="prizes__it prizes__it--2">
                                    <div className="sc__title text-center text-uppercase">
                                        Специальный <br/>
                                        розыгрыш
                                    </div>
                                    <div className="prizes__it-img">
                                        <img src={require("../assets/img/pr-2.png")}/>
                                    </div>
                                    <div className="prizes__it-descr">
                                        Подарочная карта DNS <br/>
                                        за&nbsp;регистрацию 3&nbsp;и&nbsp;более чеков
                                    </div>
                                </div>
                                <div className="prizes__it prizes__it--3">
                                    <div className="baloon__decor-1">
                                        <img src={require("../assets/img/baloon-1.svg").default}/>
                                    </div>
                                    <div className="baloon__decor-2">
                                        <img src={require("../assets/img/baloon-2.svg").default} className="desk-only"/>
                                        <img src={require("../assets/img/baloon-3.svg").default} className="mb-only"/>
                                    </div>
                                    <div className="baloon__decor-3">
                                        <img src={require("../assets/img/baloon-3.svg").default}/>
                                    </div>
                                    <div className="baloon__decor-4">
                                        <img src={require("../assets/img/baloon-4.svg").default}/>
                                    </div>
                                    <div className="sc__title text-center text-uppercase">
                                        Главный приз
                                    </div>
                                    <div className="prizes__it-img">
                                        <img src={require("../assets/img/pr-3.png")}/>
                                    </div>
                                    <div className="prizes__it-descr">
                                        Cертификат в&nbsp;турагенство на&nbsp;200&nbsp;000₽
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <CodesWidget id="prizes" classesList="btn-ct d-flex justify-content-center align-items-center" icon="true"/>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="winners" className="sc__winners">
                    <div className="winners__bg"/>
                    <div className="baloon__decor-10">
                        <img src={require("../assets/img/baloon-7.svg").default}/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                <span>победители</span>
                            </div>
                        </div>
                        <div className="win__prizes-wrap">
                            <div className="win__prizes d-flex justify-content-center align-items-start">
                                <div className="win__prizes-it win__prizes-it--1">
                                    <div className="win__prizes-it-img">
                                        <div className="win__prizes-it-img-wrap">
                                            <img src={require("../assets/img/pr-1.png")}/>
                                        </div>
                                    </div>
                                    <div className="win__prizes-it-descr">
                                        30 000 РУБЛЕЙ <br/>
                                        на банковскую карту
                                    </div>
                                </div>
                                <div className="win__prizes-it win__prizes-it--2">
                                    <div className="win__prizes-it-img">
                                        <div className="win__prizes-it-img-wrap">
                                            <img src={require("../assets/img/pr-2.png")}/>
                                        </div>
                                    </div>
                                    <div className="win__prizes-it-descr">
                                        30 000 РУБЛЕЙ <br/>
                                        подарочная карта DNS
                                    </div>
                                </div>
                                <div className="win__prizes-it win__prizes-it--3">
                                    <div className="win__prizes-it-img">
                                        <div className="win__prizes-it-img-wrap">
                                            <img src={require("../assets/img/pr-3.png")}/>
                                        </div>
                                    </div>
                                    <div className="win__prizes-it-descr">
                                        Главный приз
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="win__wrap-box">
                            <form className={'win__search' + (searchLoading ? ' form--loading' : '')} autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
                                <div className={'form__field' + ((watchEmail && watchEmail != '') ? ' has-value' : '') + (errors.email ? ' has-error' : '')}>
                                    <input
                                        type="text"
                                        placeholder="Поиск по e-mail"
                                        id="email-search"
                                        {...register("email")}
                                    />
                                </div>
                                <button type="submit" className="search__submit">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.4721 19.6282C18.7384 20.3619 17.5492 20.3619 16.8155 19.6282L13.5006 16.3132C12.1377 17.1869 10.5267 17.7085 8.78731 17.7085C3.94688 17.7085 0.0223389 13.7845 0.0223389 8.94347C0.0223389 4.10245 3.94688 0.178467 8.78731 0.178467C13.6284 0.178467 17.5523 4.10241 17.5523 8.94347C17.5523 10.6829 17.0307 12.2939 16.1571 13.6573L19.472 16.9718C20.2058 17.7054 20.2058 18.8945 19.4721 19.6282ZM8.78731 2.68277C5.32985 2.68277 2.5266 5.48601 2.5266 8.94347C2.5266 12.4015 5.32988 15.2042 8.78731 15.2042C12.2454 15.2042 15.048 12.4015 15.048 8.94347C15.048 5.48601 12.2454 2.68277 8.78731 2.68277Z" fill="#2C1E16"/>
                                    </svg>
                                </button>
                            </form>
                            {errors.email && (
                                <p className="error__message">
                                    {errors.email?.message}
                                </p>
                            )}
                            <div className="win__wrap">
                                {winners.length > 0 && (
                                    <div className={'tb tb--winners' + (loading ? ' tb--loading' : '')}>
                                        <div className="tb__head desk-only">
                                            <div className="tb__row">
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        Имя
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        E-mail
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        Приз
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tb__body">
                                            {winners.length > 0 && winners.map((win, i) => (
                                                <div className="tb__row">
                                                    <div className="tb__col">
                                                        <div data-title="Имя:" className="tb__td">
                                                            {win.name}
                                                        </div>
                                                    </div>
                                                    <div className="tb__col">
                                                        <div data-title="E-mail:" className="tb__td">
                                                            {win.email}
                                                        </div>
                                                    </div>
                                                    <div className="tb__col">
                                                        <div data-title="Приз:" className="tb__td">
                                                            {win.prize}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {winners.length === 0 && (
                                    <div className="tb__empty">
                                        {winnersEmpty}
                                    </div>
                                )}
                                {winPaging.total > 1 && (
                                    renderPager()
                                )}
                            </div>
                        </div>

                    </div>
                </div>
                <div id="faq" className="sc__faq">
                    <div className="baloon__decor-8">
                        <img src={require("../assets/img/baloon-7.svg").default}/>
                    </div>
                    <div className="baloon__decor-9">
                        <img src={require("../assets/img/baloon-8.svg").default}/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                вопрос-ответ
                            </div>
                        </div>
                        <div className="faq__wrap">
                            <div className="faq__scroll">
                                <CustomScroll heightRelativeToParent="100%">
                                    <div className="faq__list">
                                        {faq.map((fq, i) => (
                                            <div onClick={()=>{
                                                if(faqCur === (i + 1)) {
                                                    setFaqCur(-1);
                                                }else {
                                                    setFaqCur(i + 1);
                                                }

                                            }} className={'faq__it fadeIn animated' + (faqCur == (i + 1) ? ' active' : '') + (i > 4 ? ' faq__it--hidden' : '')}>
                                                <div className="faq__it-hd d-flex align-items-start justify-content-between">
                                                    <div className="faq__it-title">
                                                        {parse(fq.title)}
                                                    </div>
                                                    <div className="faq__it-icon">
                                                        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.5 1.60156L7.5 7.60156L1.5 1.60156" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="faq__it-answer fadeIn animated">
                                                    <div className="faq__it-txt">
                                                        {parse(fq.answer)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CustomScroll>
                            </div>
                        </div>
                        <div className="feedback__wrap">
                            <div className="feedback__title text-center">
                                НЕ НАШЛИ ОТВЕТ НА&nbsp;СВОЙ ВОПРОС? <br/>
                                МЫ&nbsp;ОБЯЗАТЕЛЬНО ОТВЕТИМ!
                            </div>
                            <div className="d-flex justify-content-center">
                                <div onClick={()=>{
                                    show(<Feedback/>, "modal--form modal--feedback");
                                }} className="btn-ct d-flex align-items-center justify-content-center">
                                    <span>Задать вопрос</span>
                                    <i className="btn__arrow">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20" cy="20" r="20" fill="#532010"/>
                                            <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                        </svg>
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollPercentage>
    );
}

export default Home;
