import parse from 'html-react-parser';
import React from "react";

function Info({title = '', subtitle = '', text = '', icon = false, regBtn = false, close}){
    return (
        <>
            <div className="modal__ct">
                {icon && (
                    <div className="modal__ct-box">
                        <i className="icon__success" aria-hidden={true}/>
                    </div>
                )}
                {title && (
                    <div className="modal__ct-box">
                        <div className="modal__title">
                            {parse(title)}
                        </div>
                    </div>
                )}
                {text && (
                    <div className="modal__ct-box">
                        <div className="modal__subtitle">
                            {parse(text)}
                        </div>
                    </div>
                )}
                <div className="modal__ct-box modal__action-close d-flex justify-content-center">
                    <div onClick={()=>{
                        close()
                    }} className="btn-ct btn--full btn-ct--modal d-flex align-items-center justify-content-center">
                        <span>Хорошо</span>
                        <i className="btn__arrow">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#532010"/>
                                <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Info;
