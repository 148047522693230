import React, { FunctionComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Dialog, Transition } from '@headlessui/react'

export const Modal = ({
    isShown,
    modalContent,
    hide,
    className,
  }) => {

    console.log(className);

    return(
        <Transition appear show={isShown} as={Fragment}>
            {/* Modal backdrop */}
            <Dialog as="div" className={'modal ' + className} onClose={()=>{
                hide();
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div id="modal__backdrop" className="modal__backdrop" />
                </Transition.Child>

                {/* Modal content */}
                <div id="modal__content" className="modal__content">
                    <div className="modal__content-area">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-500"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-200"
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="modal__content-box">
                                <div className="modal__content-box-bl">
                                    <div
                                        id="modal-close"
                                        className="modal__close"
                                        onClick={() => {
                                            hide();

                                            if(className.includes('modal--reg')) {
                                                if(window.ym) {
                                                    window.ym(97909310,'reachGoal', 'left_registration');

                                                    const script = document.createElement("script");
                                                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                                                        '({ sid: 228586, bt: 62, sz: "abandoned_registration_closing_form"},{ id: "", gid1: "", yid1: "" });';
                                                    document.head.appendChild(script);
                                                }

                                                if(window.dataLayer) {
                                                    window.dataLayer.push({
                                                        'event': 'customEvent',
                                                        'eventCategory': '8noReg'
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="18.333" width="25.9272" height="2.35702" transform="rotate(-45 0 18.333)" fill="white"/>
                                            <rect width="25.9272" height="2.35702" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 20 18.333)" fill="white"/>
                                        </svg>
                                    </div>

                                    {modalContent}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
};
