import { Link, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {useModal, useProfile} from "../context";
import Feedback from "./modals/Feedback";
import {goToAnchor} from "../helpers/go-to-anchor";
function Footer({screen, moveDown}) {
    const { show, hide} = useModal();
    const location = useLocation();

    return (
        <>
            <div className="footer">
                <div className="footer__bg"/>
                <div className="container">
                    <div className="footer__nav">
                        <ul>
                            <li>
                                <a href="/rules.pdf" target="_blank">Правила акции</a>
                            </li>
                            <li>
                                <a href="/policy.pdf" target="_blank">Политика конфиденциальности</a>
                            </li>
                            <li>
                                <a onClick={()=>{
                                    show(<Feedback/>, "modal--form modal--feedback");
                                }}>Обратная связь</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__legal text-center">
                        Для участия в&nbsp;акции необходимо в&nbsp;период c&nbsp;1&nbsp;августа по&nbsp;30&nbsp;сентября 2024&nbsp;г. приобрести в&nbsp;магазинах-участниках любой кофе Jardin и&nbsp;зарегистрировать чек с&nbsp;покупкой на&nbsp;сайте <a href="https://jardin.promo">jardin.promo</a>. Общий срок проведения акции (включая срок выдачи призов): с&nbsp;1&nbsp;августа по&nbsp;15&nbsp;октября 2024&nbsp;г. Количество призов ограничено. Информация об&nbsp;организаторе акции, сроках и&nbsp;правилах ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке вручения призов размещена на&nbsp;сайте <a href="https://jardin.promo">jardin.promo</a>.
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/" onClick={(e)=>{
                            goToAnchor('promo');
                        }} className="footer__logo">
                            <img src={require("../assets/img/logo.svg").default}/>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
