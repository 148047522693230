import { useEffect, useRef } from "react"

export default function useEffectOnce(cb) {
    const effectRun = useRef(false);

    useEffect(()=>{
        if (!effectRun.current) {
            cb();
        }
        return () => effectRun.current = true;
    }, [])
}