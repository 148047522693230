import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal, useProfile} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

const topics = [
    {
        title: 'Регистрация в акции',
        id: 1
    },
    {
        title: 'Получение призов',
        id: 2
    },
    {
        title: 'Технические вопросы',
        id: 3
    },
    {
        title: 'Другое',
        id: 4
    }
];

function Feedback({product = false}){
    const {profile} =  useProfile();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [theme, setTheme] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [attachFile, setAttachFile] = useState('');
    const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);

    const { show, hide} = useModal();

    const schema = yup.object({
        name: yup.string().required('Некорректное Имя'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        text: yup.string().min(6, 'Мин. 5 символов').required('Обязательно к заполнению'),
        theme_id: yup.string().required('Выберите тему обращения'),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие'),
    }).required();

    const { register,reset, setValue, clearErrors, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=>{
        setValue('agree', true);
        if(product){
            setValue('theme', 6);
        }
    }, []);

    const watchEmail = watch("email");
    const watchName = watch("name");
    const watchMess = watch("text");

    useEffect(() => {
        if(!profile.isLoading && Object.keys(profile.data).length > 0){
            setValue('name', profile.data.profile.name);
            setValue('email', profile.data.profile.email);
        }
    },[profile]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        const fileInput = document.querySelector('#file') ;
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("text", data.text);
        formData.append("subject_id", data.theme_id);
        formData.append("accept1", 1);

        if(fileInput.files[0] !== undefined){
            formData.append('file', fileInput.files[0]);
        }

        try {
            const response = await axiosInstance.post('sendFeedback', formData);
            if(response.data.result){
                reset();
                setAttachFile('');
                setThemeTitle('');
                setTheme('');
                show(<Info
                    icon={true}
                    title="спасибо <br/> за обращение"
                    text={'ваш вопрос будет обработан <br/>в&nbsp;течение 72&nbsp;часов. <br/><br/>Ответ будет отправлен <br/>на ' + data.email}
                    close={hide}/>, "modal--info");

                if(window.ym) {
                    window.ym(97909310,'reachGoal', 'ask_question');
                }

                if(window.generalPixel) {
                    window.generalPixel.postClick('a11');
                }

                const script = document.createElement("script");
                script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                    '({ sid: 228586, bt: 62, sz: "ask_question"},{ id: "", gid1: "", yid1: "" });';
                document.head.appendChild(script);

                if(localStorage.getItem('auth_key')){
                    setValue('name', profile.data.name);
                    setValue('email', profile.data.email);
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            задать вопрос
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.name ? ' has-error' : '') + ((watchName && watchName != '') ? ' has-value' : '')}>
                                        <label htmlFor="name" className="form__label">
                                            Имя
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            {...register("name")}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p className="error__message">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            E-mail
                                        </label>
                                        <input
                                            autoComplete="username"
                                            type="text"
                                            id="email"
                                            {...register("email")}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className="form__label">
                                        Тема обращения
                                    </div>
                                    <div className={'form__select' + (isThemeDropdownOpen ? ' active' : '') + (theme != '' ? ' has-value' : '') + (errors.theme_id ? ' has-error' : '')}>
                                        <div onClick={()=>{
                                            setIsThemeDropdownOpen(!isThemeDropdownOpen);
                                        }} className="form__select-input d-flex align-items-center justify-content-between">
                                            <div className={'form__select-value' + (theme == '' ? ' form__select-value--placeholder' : '')}>
                                                {theme != '' ? themeTitle : 'Выберите тему'}
                                            </div>
                                            <div className="form__select-toggle">
                                                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11 1.5L6 6.5L1 1.5" stroke="#2C1E16" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form__select-dropdown">
                                            <div className="form__dropdown-list">
                                                {topics.map((it, i) => (
                                                    <div onClick={()=>{
                                                        setIsThemeDropdownOpen(false);
                                                        setThemeTitle(it.title);
                                                        setTheme(it.id);
                                                        setValue('theme_id', it.id);
                                                        clearErrors('theme_id');
                                                    }} className={'form__dropdown-it' + (it.id == theme ? ' active' : '')}>
                                                        {it.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {errors.theme_id && (
                                        <p className="error__message">
                                            {errors.theme_id?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.text ? ' has-error' : '') + ((watchMess && watchMess != '') ? ' has-value' : '')}>
                                        <label htmlFor="text" className="form__label">
                                            Текст обращения
                                        </label>
                                        <textarea
                                            id="text"
                                            {...register("text")}
                                        />
                                    </div>
                                    {errors.text && (
                                        <p className="error__message">
                                            {errors.text?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--attach d-flex align-items-center">
                                    <label className="d-flex align-items-center form__attach-btn">
                                        <input type="file" id="file" {...register("attachment")} accept="image/*" onChange={(event)=>{
                                            const fileInput = document.querySelector('#file') ;
                                            const files = event.target.files;
                                            const fileName = files[0].name;
                                            setAttachFile(fileName);
                                        }}/>
                                        <div className={'file__attach-btn-icon d-flex align-items-center justify-content-center' + (attachFile != '' ? ' has-image' : '')}>
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="25" cy="25" r="25" fill="#532010"/>
                                                <line x1="25" y1="13" x2="25" y2="37" stroke="#E4C9B5" strokeWidth="2"/>
                                                <line x1="37" y1="25" x2="13" y2="25" stroke="#E4C9B5" strokeWidth="2"/>
                                            </svg>
                                        </div>
                                        <span>{attachFile ? attachFile : 'Изображение'}</span>

                                        {attachFile != '' && (
                                            <div onClick={(e)=>{
                                                setValue('attachment', null);
                                                setAttachFile('');
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }} className="file__clear">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2.69235L2.69231 2.00005L19.9999 19.3077L19.3076 20L2 2.69235Z" fill="#ffffff"></path><path d="M19.3077 2L20 2.69231L2.69237 20L2.00006 19.3076L19.3077 2Z" fill="#ffffff"></path></svg>
                                            </div>
                                        )}
                                    </label>

                                </div>
                                <div className="col-12 form__it">
                                    <div className={'checkbox__wrap' + (errors.agree ? ' has-error' : '')}>
                                        <label className="align-items-center">
                                            <input
                                                type="checkbox"
                                                {...register("agree")}
                                            />
                                            <div className="checkbox__decor"/>
                                            <div className="checkbox__title text-left">
                                                Даю согласие на обработку <a href="/user-agreement.pdf" target="_blank">персональных данных</a>.
                                            </div>
                                        </label>
                                    </div>
                                    {errors.agree && (
                                        <p className="error__message">
                                            {errors.agree?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <button  type="submit" className="btn-ct btn-ct--modal btn--full d-flex align-items-center justify-content-center">
                                        <span>Отправить</span>
                                        <i className="btn__arrow">
                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="20" r="20" fill="#532010"/>
                                                <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Feedback;
