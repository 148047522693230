import parse from 'html-react-parser';
import React from "react";

function Static({prize_id, close}){
    return (
        <>
            <div className="modal__ct">
                <div className="modal__static-text">
                    <p>
                        Для подтверждения статуса победителя необходимо в&nbsp;течение 2&nbsp;рабочих дней с&nbsp;момента получения данного письма выслать на&nbsp;адрес электронной почты Организатора <a href="mailto:info@jardin.promo">info@jardin.promo</a> следующие достоверные документы/сведения о&nbsp;себе:
                    </p>
                    <ol>
                        <li>
                            Фамилия, имя, отчество;
                        </li>
                        <li>
                            Контактный номер телефона;
                        </li>
                        <li>
                            Фото/скан страниц своего паспорта гражданина&nbsp;РФ (разворот с&nbsp;фотографией и&nbsp;страница с&nbsp;информацией о&nbsp;последнем месте регистрации);
                        </li>
                        <li>
                            Фото/скан свидетельства ИНН;
                        </li>
                        {prize_id === 2 && (
                            <li>
                                Полные реквизиты банковской карты для перечисления Приза (фото/скан);
                            </li>
                        )}
                        <li>
                            Скан подписанного согласия на&nbsp;обработку персональных данных в&nbsp;соответствии с&nbsp;Приложением 1&nbsp;к правилам акции. Участник обязан хранить оригинал указанного согласия на&nbsp;обработку персональных данных в&nbsp;течение 1&nbsp;года;
                        </li>
                        <li>
                            Иную необходимую информацию по запросу Организатора.
                        </li>
                    </ol>
                </div>
            </div>
        </>
    );
}

export default Static;
